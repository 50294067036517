import buildHeadTags from 'components/dynamic-page/area-listings-page/build-head-tags';
import { generateRouteMatchObjectFromPath, RouteMatchObject } from 'components/dynamic-page/route-matchers';
import extractPageFromUrl from 'utils/extract-page-from-url';
import deepmerge from 'deepmerge';
import { generateBreadcrumbs } from 'components/breadcrumbs';
import { getAddressesBySlug } from 'data/addresses';
import generateHeading from './generate-heading';
import isUserAgentCrawler, { isUserAgentLighthouse } from 'utils/crawler-agent';
import { getFilterParamsFromURLQuery } from 'utils/listing-query-helper';
import defaultListingParams from 'contexts/preferences/listing-params/defaults';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import { deDasherize } from '@zoocasa/node-kit/strings/de-dasherize';
import generateCityFooterData, { generateNeighbourhoodFooterData } from './generate-dynamic-footer-data';
import { extractListingParamsData } from 'contexts/preferences/listing-params/utils';
import { countryCodeFromProvinceOrState } from 'utils/province_or_state';
import { getThemeOverrideFromRequest, themeNameOrDefault } from 'utils/themes';
import { themes } from 'themes';
import { getListings, toJson, sortBy } from 'data/listing';
import { getNamedContents } from 'data/named-content';
import configJSON from 'config.json';
import { getFeaturesOverrideFromRequest } from 'utils/features';

import type { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import type { ListingParams } from 'contexts/preferences/listing-params/types';
import type Listing from 'data/listing';
import type { LinkDataType } from 'components/home-page/internal-links';
import type { PaginationParams } from 'utils/types';
import type { Breadcrumb } from 'components/breadcrumbs';
import type { DynamicPageServerSideProps } from 'types/dynamic_page_types';
import type { HeadDataType } from 'components/head-data';

export interface AreaListingsPageServerSideProps {
    addressesData: Record<string, unknown>[];
    listingsData: Record<string, unknown>[];
    breadcrumbs: Breadcrumb[];
    params: ListingParams & PaginationParams;
    heading: string;
    pageNumber: number;
    totalCount: number;
    totalPages: number;
    genericNamedContent: string[] | null;
    isCrawler: boolean;
    isCrawlerLighthouse: boolean;
    footerData: LinkDataType[];
    headTags: HeadDataType;
}

export default async function getServerSideProps(context: GetServerSidePropsContext): Promise<GetServerSidePropsResult<DynamicPageServerSideProps<AreaListingsPageServerSideProps>>> {
  context.res.setHeader(
    'Cache-Control',
    'public, s-maxage=1800, stale-while-revalidate=60'
  );
  const featureOverrides = getFeaturesOverrideFromRequest(context.req);
  const features = { ...configJSON.features, ...featureOverrides };
  const themeName = getThemeOverrideFromRequest(context.req);
  const theme = themes[themeNameOrDefault(themeName)];
  const { params, path } = extractUrlData(context);
  const [addresses, data] = await Promise.all([getAddressesBySlug(params.filter.slug), getListings(params, true)]);
  const { totalPages, totalCount } = data.meta ? data.meta : { totalPages: 0, totalCount: 0 };
  const listingsSortedByPrice = sortBy(data.listings as unknown as Listing[], listing => listing.price || 0, 'ascending');
  const lowestPrice = listingsSortedByPrice?.[0]?.price;
  const highestPrice = listingsSortedByPrice?.[listingsSortedByPrice.length - 1]?.price;
  const pageNumber = context.query.page ? parseInt(context.query.page.toString()) : 1;
  const routeMatchObject = generateRouteMatchObjectFromPath(path);
  const key = genericNamedContentKey(routeMatchObject);
  const heading = generateHeading(params, routeMatchObject, totalCount);
  const country = countryCodeFromProvinceOrState(routeMatchObject.provinceCode);
  const breadcrumbs = generateBreadcrumbs(country, addresses);
  const isCrawler = isUserAgentCrawler(context.req.headers);
  const isCrawlerLighthouse = isUserAgentLighthouse(context.req.headers['user-agent']);
  const routeMatchLocationType = routeMatchObject.locationType || routeMatchObject.city && 'city' || routeMatchObject.province && 'province';
  let footerData: LinkDataType[] = [];
  if (isCrawler) {
    footerData = await getFooterData({ ...routeMatchObject, locationType: routeMatchLocationType }, params.filter.latitude, params.filter.longitude, themeName, features.useLegacySearchFilter || false) || [];
  }

  let genericNamedContent: string[] | null = null;
  if (totalCount > 0 && isCrawler) {
    const namedContents = await getNamedContents({ filter: { key }});
    if (namedContents?.length > 0 ) {
      const namedContent = namedContents?.[0];
      genericNamedContent = namedContent.content.split('<p>SECTION BREAK</p>');
    }
  }

  return {
    props: {
      routeName: 'area-listings',
      props: {
        headTags: buildHeadTags(theme, path, totalCount, lowestPrice, highestPrice, breadcrumbs, data.listings as unknown as Listing[], params),
        addressesData: addresses.map((address: any) => toJson(address)),
        listingsData: data.listings.map((listing: any) => toJson(listing)),
        heading,
        breadcrumbs,
        params: { ...params, filter: { ...params.filter, providerId: params.filter.providerId || null }},
        pageNumber,
        totalPages,
        totalCount,
        genericNamedContent,
        isCrawler,
        isCrawlerLighthouse,
        footerData: footerData,
      },
    },
  };
}

export async function getFooterData(params: RouteMatchObject, lat: number, long: number, tenant, useLegacySearchFilter = false) {
  if (params.locationType === 'city') {
    const slug = params.filter.slug;
    const city = capitalizeWords(deDasherize(params.city));
    const province = params.provinceCode?.toUpperCase();
    const data = await generateCityFooterData(city, slug, tenant, province, 10, useLegacySearchFilter);
    return data;
  } else if (params.locationType === 'neighbourhood') {
    const city = capitalizeWords(deDasherize(params.city));
    const city_slug = params.cityWithProvinceCode;
    const neighbourhood_slug = params.filter.slug;
    const neighbourhood = capitalizeWords(deDasherize(params.neighbourhood));
    const province = params.provinceCode?.toUpperCase();
    const data = await generateNeighbourhoodFooterData(city, city_slug, neighbourhood, neighbourhood_slug, province, lat, long, tenant, useLegacySearchFilter);
    return data;
  }
}

export function genericNamedContentKey(routeMatchObject: RouteMatchObject) {
  const slug = routeMatchObject.filter.slug;
  const suffix = slug ? `-${slug}` : '';
  return 'locations' + suffix;
}

function extractUrlData(context: GetServerSidePropsContext) {
  const { query, resolvedUrl } = context;
  const path = (query.dynamic as string[]).join('/');
  const filter = path.includes('/filter');

  const savedSort = extractListingParamsData(context.req).sort || defaultListingParams.sort;
  
  const newParams = {
    filter: generateRouteMatchObjectFromPath(path).filter,
    page: {
      number: extractPageFromUrl(query),
      size: 28,
    },
    sort: savedSort,
  };

  let params = defaultListingParams as Required<ListingParams>;
  params = deepmerge(params, newParams);

  if (filter) {
    const filteredParams = getFilterParamsFromURLQuery(resolvedUrl.split('/filter')[1]);
    params = deepmerge(params, filteredParams);
  }

  return { params, path };
}
